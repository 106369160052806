var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"data",attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('button.B0010'),"cancel-title":_vm.$t('button.B0005'),"centered":"","title":_vm.propTitle,"size":"lg","hideHeaderClose":"","no-close-on-backdrop":"","no-close-on-esc":"","body-class":"bodyCustom","footer-class":"footerCustom","title-class":"titleCustom"},on:{"ok":_vm.onBtnOk,"cancel":_vm.onBtnCancel}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-id"}},[_vm._v(_vm._s(_vm.$t("label.L0009"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"id","rules":"required|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"off","type":"text","id":"input-id","readonly":_vm.propType == 'update' ? true : false,"maxLength":"20"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.userId),callback:function ($$v) {_vm.$set(_vm.userinfo, "userId", $$v)},expression:"userinfo.userId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]),(_vm.userIdErrorMsgToggle)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorMsg)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-username"}},[_vm._v(_vm._s(_vm.$t("label.L0138"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"name","rules":"required|kor_alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-username","type":"text","autocomplete":"off","maxLength":"10"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.userName),callback:function ($$v) {_vm.$set(_vm.userinfo, "userName", $$v)},expression:"userinfo.userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-email"}},[_vm._v(" "+_vm._s(_vm.$t("label.L0165"))+" "),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"userEmail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-email","type":"email","placeholder":"example@domain.com","maxLength":"50"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.userEmail),callback:function ($$v) {_vm.$set(_vm.userinfo, "userEmail", $$v)},expression:"userinfo.userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.userEmailErrorMsgToggle)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorMsg)+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-password"}},[_vm._v(_vm._s(_vm.$t("label.L0010"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"password","rules":"required|password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-password","type":"password","maxLength":"20"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.password),callback:function ($$v) {_vm.$set(_vm.userinfo, "password", $$v)},expression:"userinfo.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-check-pass"}},[_vm._v(_vm._s(_vm.$t("label.L0014"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"paaswordCheck","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-check-pass","type":"password","maxLength":"20"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.checkpass),callback:function ($$v) {_vm.$set(_vm.userinfo, "checkpass", $$v)},expression:"userinfo.checkpass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-branchCode"}},[_vm._v(_vm._s(_vm.$t("label.L0015")))])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"kor_alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-branchCode","type":"text","maxLength":"10"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.branchCode),callback:function ($$v) {_vm.$set(_vm.userinfo, "branchCode", $$v)},expression:"userinfo.branchCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-tel-num"}},[_vm._v(_vm._s(_vm.$t("label.L0016")))])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"tel","rules":"checkTel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"text-left",attrs:{"id":"input-tel-num","type":"text","maxLength":"15"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.telNum),callback:function ($$v) {_vm.$set(_vm.userinfo, "telNum", $$v)},expression:"userinfo.telNum"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }