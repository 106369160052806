<template>
  <div>
    <b-modal
      ref="data"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.B0001')"
      :cancel-title="$t('button.B0005')"
      centered
      :title="propTitle"
      @ok="onBtnOk"
      @cancel="onBtnCancel"
      size="lg"
      hideHeaderClose
      no-close-on-backdrop
      no-close-on-esc
      body-class="bodyCustom"
      footer-class="footerCustom"
      ok-only
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="2">
              <label for="input-id"
                >{{ $t("label.L0009")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="id"
                  rules="required|alpha_num"
                  #default="{ errors }"
                >
                  <b-form-input
                    autocomplete="off"
                    v-model="userinfo.userId"
                    type="text"
                    id="input-id"
                    :readonly="propType == 'update' ? true : false"
                    maxLength="20"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <label for="input-username"
                >{{ $t("label.L0138")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="name"
                  #default="{ errors }"
                  rules="required|kor_alpha_num"
                >
                  <b-form-input
                    id="input-username"
                    v-model="userinfo.userName"
                    type="text"
                    autocomplete="off"
                    maxLength="10"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="2">
              <label for="input-password"
                >{{ $t("label.L0010")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="password"
                  rules="required|password"
                  vid="password"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-password"
                    v-model="userinfo.password"
                    type="password"
                    @focus="initErrorMsg"
                    maxLength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <label for="input-check-pass"
                >{{ $t("label.L0014")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="paaswordCheck"
                  rules="required|confirmed:password"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-check-pass"
                    v-model="userinfo.checkpass"
                    type="password"
                    maxLength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2"> </b-col>
            <b-col md="8"
              ><small class="text-danger">{{ errorMsg }} </small>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import cryptoJS from "crypto-js";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BButtonGroup,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  name: "equipPopup",
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormValidFeedback,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BButtonGroup,
    BInputGroup,
    BInputGroupAppend,
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    propTitle: String,
    propType: String,
    passwordorg: String,
  },
  data() {
    return {
      userinfo: {
        userId: "",
        userName: "",
        password: "",
        checkpass: "",
        extension: "",
        telNum: "",
        companyCode: "",
        branchCode: "",
        team: "",
        userAuth: "",
      },
      errorMsg: "",
      resdata: null,
    };
  },
  methods: {
    async onBtnOk(data) {
      data.preventDefault();
      const success = await this.$refs.simpleRules.validate();

      if (success) {
        var userInfoObj = { ...this.userinfo };

        const crypto = require("crypto");
        const pw = userInfoObj.password; //  비밀번호
        const check_pw = userInfoObj.checkpass; //  비밀번호 확인
        const sha256Pw = crypto.createHash("sha256").update(pw).digest("hex");
        const sha256checkPw = crypto
          .createHash("sha256")
          .update(check_pw)
          .digest("hex");
        const sha256PwOrg = crypto
          .createHash("sha256")
          .update(this.passwordorg)
          .digest("hex");

        userInfoObj.password = sha256Pw;
        userInfoObj.checkpass = sha256checkPw;

        await this.api.login(userInfoObj.userId, sha256PwOrg);
        // const response = await this.api.modifyUser(userInfoObj.userId, userInfoObj);
        const response = await this.api.loginModifyUser(
          userInfoObj.userId,
          userInfoObj
        );
        if (response.data.success) {
          this.resdata.data.name.data.user.userName = userInfoObj.userName; // 변경된 사용자 명으로 업데이트
          await this.$emit("next-page", this.resdata, userInfoObj.userId);
          this.$refs.data.hide();
          this.errorMsg = "";
        } else {
          this.errorMsg = this.changeLanguage(response.data.error.code)
          // this.errorMsg = response.data.error.message;
        }
      }
    },

    // errorCode 로 다국어 처리 
    changeLanguage(errorCode){
      let msg = null;
      try{
        msg = this.$t("errorCode."+errorCode);
      }catch(err){
        msg = this.$t("message.M0057");
      }
      return msg;
    },

    initErrorMsg() {
      // 기존 비밀번호 입력시 errorMsg 초기화
      this.errorMsg = "";
    },
    showErrorModal(result) {
      if (!!result) {
        this.$swal({
          heightAuto: false,
          title: result.message,
          icon: "error",
          confirmButtonText: $t("button.B0001"),
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn btn-primary ml-1",
          },
          buttonsStyling: false,
        });
      } else {
        this.hideModal();
      }
    },
    hideModal() {
      this.$refs.data.hide();
    },
    onBtnCancel() {},
    updatePopup(data, id) {
      this.resdata = data;
      this.userinfo = {
        userId: id,
        userName: "",
        password: "",
        checkpass: "",
        extension: "",
        telNum: "",
        companyCode: "",
        userAuth: "A",
        fir: "y",
      };
      this.$refs.data.show();
    },
  },
  mounted() {},
};
</script>
<style scoped>
label {
  padding-top: 6px;
  color: #5e5873;
  font-size: 14px;
}
</style>
<style>
.bodyCustom {
  padding: 1.4rem 1.4rem 0.1rem 1.4rem;
}
.footerCustom {
  border-top-width: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 14px !important;
}
</style>
