<template>
  <div>
    <b-modal
      ref="data"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.B0010')"
      :cancel-title="$t('button.B0005')"
      centered
      :title="propTitle"
      @ok="onBtnOk"
      @cancel="onBtnCancel"
      size="lg"
      hideHeaderClose
      no-close-on-backdrop
      no-close-on-esc
      body-class="bodyCustom"
      footer-class="footerCustom"
      title-class="titleCustom"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col>
              <fieldset class="sectionFieldset form-group">
                <legend>{{$t("label.L0198")}} {{$t("label.L0205")}}</legend>
                <b-row>
                  <b-col md="2">
                    <label for="input-id"
                      >{{ $t("label.L0009")
                      }}<span class="requiredStar">*</span></label
                    >
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        name="id"
                        rules="required|alpha_num"
                        #default="{ errors }"
                      >
                        <b-form-input
                          autocomplete="off"
                          v-model="userinfo.userId"
                          type="text"
                          id="input-id"
                          :readonly="true"
                          maxLength="20"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <label for="input-username"
                      >{{ $t("label.L0138")
                      }}<span class="requiredStar">*</span></label
                    ></b-col
                  >
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        name="name"
                        #default="{ errors }"
                        rules="required|kor_alpha_num"
                      >
                        <b-form-input
                          id="input-username"
                          v-model="userinfo.userName"
                          type="text"
                          autocomplete="off"
                          :readonly="true"
                          maxLength="10"
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="2">  
                    <label for="input-email"
                      > Email <span class="requiredStar">*</span></label
                    >
                    </b-col>
                    <b-col md="10">
                      <b-form-group>
                        <validation-provider
                          name="userEmail"
                          rules="required|email"
                          #default="{ errors }"
                        >
                          <b-form-input
                            id="input-email"
                            v-model="userinfo.userEmail"
                            type="email"
                            @focus="initErrorMsg"
                            placeholder="example@domain.com"
                            :readonly="true"
                            maxLength="50"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                </b-row>
             </fieldset>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <fieldset class="sectionFieldset form-group">
                <legend>{{$t('label.L0204')}} {{$t('label.L0205')}}</legend>
                <b-row>
                  <b-col md="2">  
                    <label for="input-auth-code"
                      > {{$t('label.L0202')}} <span class="requiredStar">*</span></label
                    >
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        name="userEmail"
                        rules="required"
                        #default="{ errors }"
                      >
                        <b-form-input
                          id="input-auth-code"
                          v-model="userinfo.authCode"
                          type="text"
                          @focus="initErrorMsg"
                          maxLength="50"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small v-if="authCodeToggle == true" class="text-danger">{{ errorMsg }} </small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">  
                  </b-col>
                  <b-col md="4" class="text-right">
                    <b-form-group>
                      <b-button variant="primary" @click="resendAuthCode()">
                        {{$t('label.L0203')}} {{$t('label.L0202')}}  
                      </b-button>
                    </b-form-group>
                  </b-col>
                </b-row>
              </fieldset>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col>
              <b-row>
                <b-col md="8"
                  ><small class="text-danger">{{ errorMsg }} </small>
                </b-col>
              </b-row>
            </b-col>
          </b-row> -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import cryptoJS from "crypto-js";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BButtonGroup,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  name: "authCodePopup",
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormValidFeedback,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    propTitle: String,
    propType: String,
    // passwordorg:String
  },
  data() {
    return {
      userinfo: {
        userId: "",
        userName: "",
        userEmail : "", 
        password: "",
        checkpass: "",
        extension: "",
        telNum: "",
        companyCode: "",
        branchCode: "",
        team: "",
        userAuth: "",
        authCode: "",
      },
      loginUserData : null, 
      errorMsg: "",
      resdata: null,
      authCodeToggle : false, 
    };
  },
  methods: {
    async onBtnOk(data) {
      try{ 
        this.$emit("loaderToggle",true);
  
        data.preventDefault();
        const success = await this.$refs.simpleRules.validate();
  
        if (success) {
          // userId에 대한 인증코드를 userEmail 에서 확인 후 입력해주세요.  
          var userInfoObj = { ...this.userinfo };
  
          // 권한코드 확인 로직
          const response = await this.api.checkAuthCode({
            userName : userInfoObj.userName,
            userId : userInfoObj.userId,
            authCode : userInfoObj.authCode
          });
  
  
          if (response.data.success) {
            this.$refs.data.hide();
            this.errorMsg = "";
  
            await this.$emit(
              "nextEvent",
              this.loginUserData, 
              this.userinfo.userId,
            );

          } else {
            this.$emit("loaderToggle",false);
  
            this.showErrorModal(response.data)
            // 아이디 이미 있는 경우
            this.authCodeToggle = true;

            this.errorMsg = this.changeLanguage(response.data.error.code)
            // this.errorMsg = response.data.error.message; //이미 존재하는 아이디입니다.
  
          }
        }
        this.$emit("loaderToggle",false);

      }catch (err){
        this.$emit("loaderToggle",false);
      }

    },

    // errorCode 로 다국어 처리 
    changeLanguage(errorCode){
      let msg = null;
      try{
        msg = this.$t("errorCode."+errorCode);
      }catch(err){
        msg = this.$t("message.M0057");
      }
      return msg;
    },

    // 이메일 재전송
    async resendAuthCode(){
      this.$emit("loaderToggle",true);

      var userInfoObj = { ...this.userinfo };

      const response = await this.api.sendEmail({
        userName : userInfoObj.userName,
        userId : userInfoObj.userId,
        userEmail : userInfoObj.userEmail,
      });
      const result = response.data;

      // 이메일 전송 성공여부 체크
      if(result.success){
        this.showModal(result);
      }else{
        this.showErrorModal(result.error.code);
      }
      // return response;
      this.$emit("loaderToggle",false);

    },
    initErrorMsg() {
      // 기존 비밀번호 입력시 errorMsg 초기화
      this.errorMsg = "";
      this.authCodeToggle = false;
    },
    showErrorModal(errorCode) {
      let msg = this.changeLanguage(errorCode);
      if (!!errorCode) {
        this.$swal({
          heightAuto: false,
          title: msg,  
          // text: result.error.message,
          icon: "error",
          confirmButtonText: this.$t("button.B0001"),
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn btn-primary ml-1",
            popup: 'custom-swal2-popup'
          },
          buttonsStyling: false,
        });
      } else {
        this.hideModal();
      }
    },

    // errorCode 로 다국어 처리 
    changeLanguage(errorCode){
      let msg = null;
      try{
        msg = this.$t("errorCode."+errorCode);
      }catch(err){
        msg = this.$t("errorCode.default");
      }
      return msg;
    },

    showModal(result) {

      if (!!result) {
        this.$swal({
          heightAuto: false,
          title: this.$t("message.M0068"),
          // title: "회원님의 이메일로 <br> 인증코드를 재전송했습니다.",  
          // text: result.error.message,
          icon: "success",
          confirmButtonText: this.$t("button.B0001"),
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn btn-primary ml-1",
            popup: 'custom-swal2-popup'
          },
          buttonsStyling: false,
        });
      } else {
        this.hideModal();
      }
    },
    hideModal() {
      this.$refs.data.hide();
    },
    onBtnCancel() {},
    authCodePopup(data, userId ) {
     
      // 로그인에 필요한 hasToken, accessToken 같은 정보 가지고있음 
      this.loginUserData = data; 
      let user = data.data.name.data.user;
      const userName = user.userName;
      const userEmail = user.userEmail;

      this.userinfo = {
        userId: userId,
        userName: userName,
        password: "",
        checkpass: "",
        userEmail : userEmail, 
        extension: "",
        telNum: "",
        companyCode: "",
        branchCode: "",
        userAuth: "",
        fir: "",
        authCode: "",
      };

      this.authCodeToggle = false;

      // 인증코드 재발송인 경우 실행 
      if(this.propType === "ExpireRefresh"){
        this.resendAuthCode();
      }

      this.$refs.data.show();
    },
  },
  mounted() {},
};
</script>
<style scoped>
.sectionFieldset {
  border: solid 1px rgba(0, 0, 0, 0.125);
  padding-top: 0px;
  padding-right: 12px;
  padding-bottom: 0px;
  padding-left: 12px;
}

.sectionFieldset legend {
  width: inherit;
  /* Or auto */
  padding: 0 10px;
  /* To give a bit of padding on the left and right */
  border-bottom: none;
  font-weight: bold;
  font-size: 12px;
}
.sectionFieldset .custom-control-inline {
  margin-right: 2rem;
}
label {
  padding-top: 6px;
  color: #5e5873;
  font-size: 14px;
}
</style>
<style>
.bodyCustom {
  padding: 1.4rem 1.4rem 0.1rem 1.4rem;
}
.footerCustom {
  border-top-width: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 14px !important;
}
.titleCustom {
  font-weight: bold;
}
.custom-swal2-popup .swal2-content {
  display: none; /* swal2-content을 숨김 */
}

</style>
