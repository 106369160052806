<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div
      style="
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        z-index: 9999;
        background-color: rgba(22, 29, 49, 0.63);
        opacity: 1;
        margin-left: -1.5rem;
        justify-content: center;
        align-items: center;
      "
      v-if="loader"
    >
      <!-- <img
        :src="require('@/assets/loader.svg')"
      /> -->
      <img
        :src="require('@/assets/loader.svg')"
        style="margin-left: 45%; margin-top: 20%"
      />
    </div>

    <!-- Login v1 -->
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <feather-icon icon="MonitorIcon" ></feather-icon> -->
          <b-img
            src="../../../../public/tdt_login.svg"
            style="width: 280px"
          ></b-img>
          <!-- <h2
            class="brand-text ml-1"
            style="color: #001f60"
          >
          &nbsp;&nbsp;&nbsp;&nbsp;TRAFFIC<br /> DIGITAL  TWIN
          </h2> -->
        </b-link>

        <b-card-title class="mb-1">
          <!-- Welcome to KATECH -->
        </b-card-title>
        <b-card-text class="mb-2">
          <!-- Please sign-in to your account -->
        </b-card-text>

        <!-- form -->
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group label-for="email" :label="$t('label.L0009')">
              <validation-provider
                #default="{ errors }"
                name="id"
                rules="required|alpha_num"
              >
                <b-form-input
                  id="Id"
                  v-model="userId"
                  autocomplete="off"
                  name="Id"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('message.M0002')"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">{{ $t("label.L0010") }}</label>
                <!-- <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge merge-custom-hja"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    :placeholder="$t('message.M0004')"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-card-text class="mb-2" style="color: red">
              <span v-html="formattedErrorMsg"> </span>
            </b-card-text>

            <b-form-group class="col-12 form-padding">
              <b-row align-v="center">
                <b-col align="left" class="form-padding">
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    {{ $t("label.L0012") }}
                  </b-form-checkbox>
                </b-col>
                <b-col align="right" class="form-padding">
                  <b-link href="#" @click="signUpPopup">
                    {{ $t("label.L0011") }}
                  </b-link>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group> </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="login(userId, password, 'login')"
            >
              {{ $t("label.L0013") }}
            </b-button>
            <!-- <b-row>
              <b-button-group
                style="width: 100%; height: 20px; margin-top: 1em"
              >
                <b-button
                  variant="light"
                  style="
                    width: 33.3%;
                    padding: 0;
                    background-color: transparent;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    margin-left: 60px;
                  "
                  @click="changeLocale('en')"
                  ><img
                    src="@/assets/images/flags/en.jpg"
                    alt="icon"
                    style="height: 30%; width: 30%"
                /></b-button>
                <b-button
                  variant="light"
                  style="
                    width: 33.3%;
                    padding: 0;
                    background-color: transparent;
                    justify-content: center;
                    align-items: center;
                    border: none;
                  "
                  @click="changeLocale('id')"
                  ><img
                    src="@/assets/images/flags/id.jpg"
                    alt="icon"
                    style="height: 30%; width: 30%"
                /></b-button>
                <b-button
                  variant="light"
                  style="
                    width: 33.3%;
                    padding: 0;
                    background-color: transparent;
                    justify-content: center;
                    align-items: center;
                    border: none;
                  "
                  @click="changeLocale('ko')"
                  ><img
                    src="@/assets/images/flags/ko.jpg"
                    alt="icon"
                    style="height: 30%; width: 30%"
                /></b-button>
              </b-button-group>
            </b-row> -->
          </b-form>
        </validation-observer>

        <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text>         -->
      </b-card>
      <!-- /Login v1 -->
      <user-modal
        ref="update"
        :propTitle="$t('label.L0137')"
        :propType="'update'"
        :passwordorg="password"
        @next-page="nextEvent"
      />
      <auth-code-modal
        ref="authCodeModal"
        :propTitle="propTitle"
        :propType="propType"
        :passwordorg="password"
        @nextEvent="nextEvent"
        @loaderToggle="loaderToggle"
      />
      <sign-up-modal
        ref="signUpModal"
        :propTitle="$t('label.L0011')"
        :propType="'create'"
        :passwordorg="password"
        @authCodeAlert="authCodeAlert"
        @loaderToggle="loaderToggle"
      />
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import userModal from "./userPopup.vue";
import signUpModal from "./signUp.vue";
import authCodeModal from "./authCodePopup.vue";

import {
  BRow,
  BCol,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BImg,
} from "bootstrap-vue";

import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store";
import metabuildmenu from "@/navigation/vertical/metabuildmenu";

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BImg,
    userModal,
    signUpModal,
    authCodeModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userId: null,
      password: "",
      status: "",
      // validation rules
      errorMsg: null,
      loader: false,
      propTitle: null,
      propType: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    formattedErrorMsg() {
      let msg = this.errorMsg;

      if (msg == null) return null;

      msg = msg.replace(/\n/g, "<br>");
      return msg;
    },
  },
  methods: {
    changeLocale(value) {
      this.$i18n.locale = value;
      this.$swal({
        icon: "success",
        title: this.$t("button.B0003"),
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          cancelButton: "btn btn-outline-danger",
        },
      });
    },
    signUpPopup() {
      this.propTitle = "회원가입";
      this.propType = "signUp";
      this.$refs.signUpModal.signUpPopup();
    },
    loaderToggle(loaderTF) {
      this.loader = loaderTF;
    },
    authCodeAlert() {
      this.$swal({
        heightAuto: false,
        // title:
        //   "회원님의 이메일로 <br> 인증코드를 전송했습니다.<br> 확인 후 다시 로그인 해주세요.",
        title: this.$t("message.M0069"),
        icon: "success",
        confirmButtonText: this.$t("button.B0001"),
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
        },
        buttonsStyling: false,
      });
    },

    // 기간 만료시 인증코드 재전송
    async expireRefresh(data, userId) {
      // this.propTitle = "재인증"
      this.propTitle =
        this.$t("label.L0196") + "( " + this.$t("label.L0194") + " )";
      this.propType = "ExpireRefresh";

      await this.firstLog();
      this.$swal({
        heightAuto: false,
        title: this.$t("message.M0067"),
        // title: "사용기간이 만료되었습니다. <br /> 인증코드를 재입력하시겠습니까?",
        // title: "$t('message.M0017')",
        icon: "info",
        confirmButtonText: this.$t("button.B0001"),
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          popup: "custom-swal2-popup",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result) {
          await this.$refs.authCodeModal.authCodePopup(data, userId);
        }
      });
    },

    // 최초 로그인시 인증코드 전송
    async firstLogin(data, userId) {
      // this.propTitle = "최초 로그인";
      this.propTitle =
        this.$t("label.L0196") + "( " + this.$t("label.L0195") + " )";
      this.propType = "FirstLogin";

      await this.firstLog();
      this.$swal({
        heightAuto: false,
        title: this.$t("message.M0066"),
        // title: "최초 로그인했습니다. <br /> 인증코드를 입력해 주세요.",
        // title: "$t('message.M0017')",
        icon: "info",
        confirmButtonText: this.$t("button.B0001"),
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn btn-primary ml-1",
          popup: "custom-swal2-popup",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result) {
          await this.$refs.authCodeModal.authCodePopup(data, userId);
        }
      });
    },

    async login(userId, userPassword, type) {
      this.errorMsg = null;
      // const { userId } = this;
      // const userId = idTest;
      let loginFormValidTF = false;
      loginFormValidTF = await this.$refs.loginForm.validate();

      if (loginFormValidTF) {
        //shs256 암호화
        const crypto = require("crypto");
        const sha256Pw = crypto
          .createHash("sha256")
          .update(userPassword)
          .digest("hex");

        var response = await this.api.login(userId, sha256Pw);
        var data = await response.data;

        if (data.success) {
          await this.checkLoginYn(data, userId);
          // }
          //  else if(data.error.code === "A011"){ // 계정 만료시
          //   this.errorMsg = response.data.error.message;
          //   await this.expireRefresh(data,userId);
        } else {
          // 그외 에러 발생시
          this.errorMsg = this.changeLanguage(response.data.error.code);
          // this.errorMsg = response.data.error.message;
        }
      } else {
        this.errorMsg = "error valid";
        return false;
      }
    },

    // 로그인 가능 여부 체크
    async checkLoginYn(data, userId) {
      // 인증코드 체크가 yes 로 되어있지 않다면 최초 로그인 로직 실행
      if (data.data.availableAccount == "n") {
        // 계정 만료시
        this.expireRefresh(data, userId);
      } else if (data.data.checkAuthCodeYn == "n") {
        // 처음 로그인시 email 인증 필요
        this.firstLogin(data, userId);
      } else {
        await this.nextEvent(data, userId);
      }
      // async firstLogin(data, userId) {
      //   if (data.data.fir == "n") {
      //     await this.firstLog();
      //     this.$swal({
      //       heightAuto: false,
      //       title: "$t('message.M0017')",
      //       icon: "info",
      //       confirmButtonText: $t("button.B0001"),
      //       allowEscapeKey: false,
      //       allowOutsideClick: false,
      //       customClass: {
      //         confirmButton: "btn btn-primary ml-1",
      //       },
      //       buttonsStyling: false,
      //     }).then(async (result) => {
      //       if (result) {
      //         await this.$refs.update.updatePopup(data, userId);
      //       }
      //     });
      //   } else {
      //     await this.nextEvent(data, userId);
      //   }
    },

    // errorCode 로 다국어 처리
    changeLanguage(errorCode) {
      let msg = null;
      try {
        msg = this.$t("errorCode." + errorCode);
      } catch (err) {
        msg = this.$t("message.M0057");
      }
      return msg;
    },

    async nextEvent(data, userId) {
      // const { userId } = this;
      this.api
        .getJson()
        .then((res) => {
          return res.data.data;
        })
        .then((json) => {
          this.$store.commit("settingConfig/setJsonFile", json);
        });
      var accessToken = data.data.accessToken;
      await this.api.setDefaultHeader("Authorization", accessToken);
      this.$cookies.set("hasToken", true);
      this.$cookies.set("accessToken", accessToken);
      this.$storage.set("auth", data.data.auth);
      if (this.status) {
        localStorage.setItem("remember", userId);
      } else {
        localStorage.removeItem("remember");
      }

      const userData = {
        userid: userId,
        username: data.data.name.data.user.userName,
        authorId: data.authorId,
        userAuth: data.data.userAuth,
        userAuthName: data.data.userAuth == "A" ? "admin" : "user",
        regDate: data.data.regDate,
        ability: [
          {
            action: "manage",
            subject: "all",
          },
        ],
        eventPopupTimer: Number(data.data.eventPopupTimer),
        extras: {
          eCommerceCartItemsCount: 0,
        },
        // speedColor: data.data.name.data.user.speedColor,
      };
      const audioSoundType = {
        eventType: [
          {
            stoppedvehicleVal: "0.0",
            pedestrian: "0.0",
            wrongway: "0.0",
            sudden_stop: "0.0",
            fire: "0.0",
            jam: "0.0",
            accident_sound: "0.0",
            horn_sound: "0.0",
            sudden_sound: "0.0",
            noise: "0.0",
          },
        ],
      };
      let menu = null;
      menu = [
        // {
        //   header: " ",
        // },
        ...metabuildmenu,
      ];
      userData.menu = menu; // 새로고침 방지용 메뉴 저장
      store.commit("verticalMenu/UPDATE_MENU", menu);
      store.commit("verticalMenu/USERDATA", userData);
      localStorage.setItem("userData", JSON.stringify(userData));
      localStorage.setItem("audioSoundType", JSON.stringify(audioSoundType));
      sessionStorage.setItem("check", true);
      localStorage.setItem("tabs", 1);
      this.$ability.update(userData.ability);
      // this.$router.push({ name: "event" });
      this.$router.push({ name: "dashboard" });
      // this.$router.push({ name: "selectProject" });
      // this.$router
      //   .replace('/')
      //   .then(() => {

      //   })
    },
    async firstLog() {
      this.$cookies.remove("hasToken");
      this.$cookies.remove("accessToken");
      this.jwt.disableToken();
      this.api.removeDefaultHeader("Authorization");
    },
    async updateLog() {
      await this.nextEvent(response.data);
    },
  },
  created() {
    // 로그인폼 무조건 영문 처리
    this.$i18n.locale = "en";

    this.errorMsg = null;
    if (localStorage.getItem("remember")) {
      this.status = true;
      this.userId = localStorage.getItem("remember");
    }
  },
  destroyed() {},
  beforeUnmount() {},
  mounted() {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
<style scoped>
.merge-custom-hja {
  margin-left: 0px !important;
}
.form-padding {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
.row {
  margin-right: 0rem !important;
  margin-left: 0rem !important;
}
.custom-swal2-popup .swal2-content {
  display: none; /* swal2-content을 숨김 */
}
</style>
