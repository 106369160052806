var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"data",attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('button.B0001'),"cancel-title":_vm.$t('button.B0005'),"centered":"","title":_vm.propTitle,"size":"lg","hideHeaderClose":"","no-close-on-backdrop":"","no-close-on-esc":"","body-class":"bodyCustom","footer-class":"footerCustom","ok-only":""},on:{"ok":_vm.onBtnOk,"cancel":_vm.onBtnCancel}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-id"}},[_vm._v(_vm._s(_vm.$t("label.L0009"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"id","rules":"required|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"off","type":"text","id":"input-id","readonly":_vm.propType == 'update' ? true : false,"maxLength":"20"},model:{value:(_vm.userinfo.userId),callback:function ($$v) {_vm.$set(_vm.userinfo, "userId", $$v)},expression:"userinfo.userId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-username"}},[_vm._v(_vm._s(_vm.$t("label.L0138"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"name","rules":"required|kor_alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-username","type":"text","autocomplete":"off","maxLength":"10"},model:{value:(_vm.userinfo.userName),callback:function ($$v) {_vm.$set(_vm.userinfo, "userName", $$v)},expression:"userinfo.userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-password"}},[_vm._v(_vm._s(_vm.$t("label.L0010"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"password","rules":"required|password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-password","type":"password","maxLength":"20"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.password),callback:function ($$v) {_vm.$set(_vm.userinfo, "password", $$v)},expression:"userinfo.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-check-pass"}},[_vm._v(_vm._s(_vm.$t("label.L0014"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"paaswordCheck","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-check-pass","type":"password","maxLength":"20"},model:{value:(_vm.userinfo.checkpass),callback:function ($$v) {_vm.$set(_vm.userinfo, "checkpass", $$v)},expression:"userinfo.checkpass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}}),_c('b-col',{attrs:{"md":"8"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorMsg)+" ")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }