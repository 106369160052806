<template>
  <div>
    <b-modal
      ref="data"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.B0010')"
      :cancel-title="$t('button.B0005')"
      centered
      :title="propTitle"
      @ok="onBtnOk"
      @cancel="onBtnCancel"
      size="lg"
      hideHeaderClose
      no-close-on-backdrop
      no-close-on-esc
      body-class="bodyCustom"
      footer-class="footerCustom"
      title-class="titleCustom"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="2">
              <label for="input-id"
                >{{ $t("label.L0009")
                }}<span class="requiredStar">*</span></label
              >
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="id"
                  rules="required|alpha_num"
                  #default="{ errors }"
                >
                  <b-form-input
                    autocomplete="off"
                    v-model="userinfo.userId"
                    type="text"
                    id="input-id"
                    :readonly="propType == 'update' ? true : false"
                    @focus="initErrorMsg"
                    maxLength="20"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                  <small v-if="userIdErrorMsgToggle" class="text-danger">{{ errorMsg }} </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <label for="input-username"
                >{{ $t("label.L0138")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="name"
                  #default="{ errors }"
                  rules="required|kor_alpha_num"
                >
                  <b-form-input
                    id="input-username"
                    v-model="userinfo.userName"
                    @focus="initErrorMsg"
                    type="text"
                    autocomplete="off"
                    maxLength="10"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="2">
              <label for="input-email"
                > {{ $t("label.L0165")
                }} <span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="10">
              <b-form-group>
                <validation-provider
                  name="userEmail"
                  rules="required|email"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-email"
                    v-model="userinfo.userEmail"
                    type="email"
                    @focus="initErrorMsg"
                    placeholder="example@domain.com"
                    maxLength="50"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small v-if="userEmailErrorMsgToggle" class="text-danger">{{ errorMsg }} </small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <label for="input-password"
                >{{ $t("label.L0010")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="password"
                  rules="required|password"
                  vid="password"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-password"
                    v-model="userinfo.password"
                    type="password"
                    @focus="initErrorMsg"
                    maxLength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <label for="input-check-pass"
                >{{ $t("label.L0014")
                }}<span class="requiredStar">*</span></label
              ></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="paaswordCheck"
                  rules="required|confirmed:password"
                  #default="{ errors }"
                >
                  <b-form-input
                    id="input-check-pass"
                    v-model="userinfo.checkpass"
                    type="password"
                    @focus="initErrorMsg"
                    maxLength="20"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="2">
              <label for="input-branchCode">{{ $t("label.L0015") }}</label>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  rules="kor_alpha_num"
                >
                  <b-form-input
                    id="input-branchCode"
                    v-model="userinfo.branchCode"
                    type="text"
                    @focus="initErrorMsg"
                    maxLength="10"
                  />
                  <small class="text-danger">
                    {{ errors[0] }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <label for="input-tel-num">{{ $t("label.L0016") }}</label></b-col
            >
            <b-col md="4">
              <b-form-group>
                <validation-provider
                  name="tel"
                  rules="checkTel"
                  v-slot="{ errors }"
                >
                  <b-form-input
                    class="text-left"
                    id="input-tel-num"
                    v-model="userinfo.telNum"
                    @focus="initErrorMsg"
                    type="text"
                    maxLength="15"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col md="2"> </b-col>
            <b-col md="8"
              ><small class="text-danger">{{ errorMsg }} </small>
            </b-col>
          </b-row> -->
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import cryptoJS from "crypto-js";
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormValidFeedback,
  BFormInvalidFeedback,
  BButtonGroup,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  name: "equipPopup",
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormValidFeedback,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    propTitle: String,
    propType: String,
    // passwordorg:String
  },
  data() {
    return {
      userIdErrorMsgToggle : false,
      userEmailErrorMsgToggle : false,
      userinfo: {
        userId: "",
        userName: "",
        userEmail : "", 
        password: "",
        checkpass: "",
        extension: "",
        telNum: "",
        companyCode: "",
        branchCode: "",
        team: "",
        userAuth: "",
      },
      errorMsg: "",
      resdata: null,
    };
  },
  methods: {
    async onBtnOk(data) {
      try{
        this.$emit("loaderToggle",true);
        data.preventDefault();
        const success = await this.$refs.simpleRules.validate();
        this.errorMsg = "";
        this.userIdErrorMsgToggle = false;
        this.userEmailErrorMsgToggle = false;

        if (success) {
          var userInfoObj = { ...this.userinfo };

          const crypto = require("crypto");
          const pw = userInfoObj.password; //  비밀번호
          const check_pw = userInfoObj.checkpass; //  비밀번호 확인
          const sha256Pw = crypto.createHash("sha256").update(pw).digest("hex");
          const sha256checkPw = crypto
            .createHash("sha256")
            .update(check_pw)
            .digest("hex");

          userInfoObj.password = sha256Pw;
          userInfoObj.checkpass = sha256checkPw;

          const response = await this.api.addNewUser(userInfoObj);
          if (response.data.success) {
            this.$refs.data.hide();
            this.errorMsg = "";

            await this.$emit(
              "authCodeAlert"
            );
            // await this.$emit(
            //   "authCodeAlert",
            //   this.userinfo.userId,
            //   this.userinfo.password,
            //   this.userinfo.userEmail, 
            //   "signUp"
            // );
          } else {
            this.$emit("loaderToggle",false);
            const errorCode = response.data.error.code;
            this.showErrorModal(errorCode);
            if(errorCode == "V001"){
              // 아이디 중복 
              this.userIdErrorMsgToggle = true;
            }else if(errorCode == "V002"){
              // 이메일 중복 
              this.userEmailErrorMsgToggle = true;
            }

            this.errorMsg = this.changeLanguage(errorCode)
            // this.errorMsg = response.data.error.message; // 아이디 또는 Email 이미 존재
          }
        }
        this.$emit("loaderToggle",false);
      }catch(err){
        this.$emit("loaderToggle",false);
        // this.errorMsg = err; // 아이디 또는 Email 이미 존재
      }
    },

    // errorCode 로 다국어 처리 
    changeLanguage(errorCode){
      let msg = null;
      try{
        msg = this.$t("errorCode."+errorCode);
      }catch(err){
        msg = this.$t("errorCode.default");
      }
      return msg;
    },

    initErrorMsg() {
      // 기존 비밀번호 입력시 errorMsg 초기화
      this.errorMsg = "";
      this.userIdErrorMsgToggle = false;
      this.userEmailErrorMsgToggle = false;
    },
    showErrorModal(errorCode) {
      let msg = this.changeLanguage(errorCode);
      if (!!errorCode) {
        this.$swal({
          heightAuto: false,
          title: msg,
          icon: "error",
          confirmButtonText: this.$t("button.B0001"),
          allowEscapeKey: false,
          allowOutsideClick: false,
          customClass: {
            confirmButton: "btn btn-primary ml-1",
            popup: 'custom-swal2-popup'
          },
          buttonsStyling: false,
        });
      } else {
        this.hideModal();
      }
    },
    hideModal() {
      this.$refs.data.hide();
    },
    onBtnCancel() {
    },
    signUpPopup() {
      this.userIdErrorMsgToggle = false,
      this.userEmailErrorMsgToggle = false,
      // this.resdata = data;
      this.userinfo = {
        userId: "",
        userName: "",
        password: "",
        checkpass: "",
        userEmail : "", 
        extension: "",
        telNum: "",
        companyCode: "",
        branchCode: "",
        userAuth: "U",
        fir: "y",
      };

      this.$refs.data.show();
    },
  },
  created() {

  },
  mounted() {

  },
};
</script>
<style scoped>
label {
  padding-top: 6px;
  color: #5e5873;
  font-size: 14px;
}
</style>
<style>
.bodyCustom {
  padding: 1.4rem 1.4rem 0.1rem 1.4rem;
}
.footerCustom {
  border-top-width: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 14px !important;
}
.titleCustom {
  font-weight: bold;
}
</style>
