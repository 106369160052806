var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"data",attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('button.B0010'),"cancel-title":_vm.$t('button.B0005'),"centered":"","title":_vm.propTitle,"size":"lg","hideHeaderClose":"","no-close-on-backdrop":"","no-close-on-esc":"","body-class":"bodyCustom","footer-class":"footerCustom","title-class":"titleCustom"},on:{"ok":_vm.onBtnOk,"cancel":_vm.onBtnCancel}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',[_c('fieldset',{staticClass:"sectionFieldset form-group"},[_c('legend',[_vm._v(_vm._s(_vm.$t("label.L0198"))+" "+_vm._s(_vm.$t("label.L0205")))]),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-id"}},[_vm._v(_vm._s(_vm.$t("label.L0009"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"id","rules":"required|alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"off","type":"text","id":"input-id","readonly":true,"maxLength":"20"},model:{value:(_vm.userinfo.userId),callback:function ($$v) {_vm.$set(_vm.userinfo, "userId", $$v)},expression:"userinfo.userId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-username"}},[_vm._v(_vm._s(_vm.$t("label.L0138"))),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"name","rules":"required|kor_alpha_num"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-username","type":"text","autocomplete":"off","readonly":true,"maxLength":"10"},model:{value:(_vm.userinfo.userName),callback:function ($$v) {_vm.$set(_vm.userinfo, "userName", $$v)},expression:"userinfo.userName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-email"}},[_vm._v(" Email "),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"10"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"userEmail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-email","type":"email","placeholder":"example@domain.com","readonly":true,"maxLength":"50"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.userEmail),callback:function ($$v) {_vm.$set(_vm.userinfo, "userEmail", $$v)},expression:"userinfo.userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])],1),_c('b-row',[_c('b-col',[_c('fieldset',{staticClass:"sectionFieldset form-group"},[_c('legend',[_vm._v(_vm._s(_vm.$t('label.L0204'))+" "+_vm._s(_vm.$t('label.L0205')))]),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('label',{attrs:{"for":"input-auth-code"}},[_vm._v(" "+_vm._s(_vm.$t('label.L0202'))+" "),_c('span',{staticClass:"requiredStar"},[_vm._v("*")])])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"userEmail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-auth-code","type":"text","maxLength":"50"},on:{"focus":_vm.initErrorMsg},model:{value:(_vm.userinfo.authCode),callback:function ($$v) {_vm.$set(_vm.userinfo, "authCode", $$v)},expression:"userinfo.authCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.authCodeToggle == true)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.errorMsg)+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}}),_c('b-col',{staticClass:"text-right",attrs:{"md":"4"}},[_c('b-form-group',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.resendAuthCode()}}},[_vm._v(" "+_vm._s(_vm.$t('label.L0203'))+" "+_vm._s(_vm.$t('label.L0202'))+" ")])],1)],1)],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }